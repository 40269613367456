<template>
  <div class="redBlueCenterAll">
    <div class="redBlueCenterFirst">
      <div class="redBlueCenterFirst1">
        <h2>御之安红蓝对抗平台</h2>
        <p>
          凭借御之安团队内部成员的黑产对抗经验、以及多维度的威胁情报，模拟黑产主流攻击手段，在业务上线之前，对业务进行全方位测评，以攻促防，提升企业黑产对抗能力，做到业务漏洞尽早发现，策略效果了如指掌。
        </p>
      </div>
    </div>
    <div class="box">
      <ul class="tabs clearfix">
        <li
          class="tab-link"
          onclick="javascript:document.getElementById('proGs').scrollIntoView()"
        >
          产品概述
        </li>
        <li
          class="tab-link"
          onclick="javascript:document.getElementById('proNr').scrollIntoView()"
        >
          服务内容
        </li>
        <li
          class="tab-link"
          onclick="javascript:document.getElementById('proGc').scrollIntoView()"
        >
          对抗过程
        </li>
        <li
          class="tab-link"
          onclick="javascript:document.getElementById('proYss').scrollIntoView()"
        >
          产品优势
        </li>
      </ul>
    </div>
    <div class="redBlueCenterSecond">
      <h1 id="proGs">产品概述</h1>
      <div class="redBlueCenterSecond1">
        <div class="redBlueCenterSecond1Left">
          <img src="../../../../assets/img/proctCenter/11.webp" alt="" />
        </div>
        <div class="redBlueCenterSecond1Right">
          <p>
            模拟黑产攻击方式，对业务发起攻击测评，检验业务安全防护能力。代替传统手动测评方式，将测评流程预置在平台中，实现系统自动化攻击测评。
            直接测评风控工具如设备指纹、风险识别、接口防护的能力和效果。测评第三方采购服务及情报数据的准确性。
          </p>
          <p>
            近年来，网络攻击日益加剧，网络安全事件多不胜数，国家信息基础设施遭受破坏，在政治、经济上造成严重影响，攻击主体也从个人演进成了组织。基于目前的网络安全态势，国家每年开展针对关键信息基础设施的实战攻防演习，以攻促防，通过演习行动发现问题，引导企事业单位进行网络安全建设。
          </p>
        </div>
      </div>
    </div>
    <div class="redBlueCenterThird">
      <h1 id="proNr">服务内容</h1>
      <div class="redBlueCenterThird1">
        <div class="redBlueCenterThird1Left">
          <p>
            红蓝对抗实战攻防演练服务通过攻防对抗实战化演练，实现网络问题的可视化，解决用户网络现存的问题、补全防御漏洞。整合用户安全威胁检测能力、应急处置能力和防护能力，提高并完善企业安全防护技术与管理体系。
          </p>
        </div>
        <div class="redBlueCenterThird1Right">
          <img src="../../../../assets/img/proctCenter/12.webp" alt="" />
        </div>
      </div>
      <p>
        红蓝对抗训练,是军事训练的重要形式,是训练方式转变,组训模式转型的基本方向.依托红蓝对抗训练平台,探索实践政治工作训练演练的方法路子,对有效提升政治工作实战化水平,拓展深化政治工作准备,具有十分重要的现实意义.
      </p>
    </div>
    <div class="redBlueCenterFourth" id="proGc">
      <img
        src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimage.3001.net%2Fimages%2F20200521%2F1590049555_5ec63b1347883.png&refer=http%3A%2F%2Fimage.3001.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1672913084&t=aa1f566a6c618cf5626d8ed0b8045ec9"
        alt=""
      />
    </div>
    <div class="codeAuditThird">
      <h1 id="proYss">产品优势</h1>
      <div class="codeAuditThird1">
        <ul>
          <li>
            <div class="codeAuditThird1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/11-1.webp"
                alt=""
              />
            </div>
            <div class="codeAuditThird1-2">
              <p class="codeAuditThird1-2-1">风险全面</p>
              <span class="codeAuditThird1-2-2">
                内置数十种主流黑产攻击手段，支持风险特征隐藏
              </span>
            </div>
          </li>
          <li>
            <div class="codeAuditThird1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/22-2.webp"
                alt=""
              />
            </div>
            <div class="codeAuditThird1-2">
              <p class="codeAuditThird1-2-1">红蓝全方位服务</p>
              <span class="codeAuditThird1-2-2">
                御之安可提供攻击方（红）、防守方（蓝）的全面服务。
              </span>
            </div>
          </li>
          <li>
            <div class="codeAuditThird1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/33-1.webp"
                alt=""
              />
            </div>
            <div class="codeAuditThird1-2">
              <p class="codeAuditThird1-2-1">持续对抗</p>
              <span class="codeAuditThird1-2-2">
                持续与业务风控进行攻防对抗，不断提升风控防御能力
              </span>
            </div>
          </li>
          <li>
            <div class="codeAuditThird1-1">
              <img
                src="https://www.boundaryx.com/wp-content/uploads/2022/05/44.webp"
                alt=""
              />
            </div>
            <div class="codeAuditThird1-2">
              <p class="codeAuditThird1-2-1">攻击能力</p>
              <span class="codeAuditThird1-2-2">
                攻击知识库积累：御之安安全服务团队积累并不断更新攻击知识库，包括：漏洞库、指纹库、0Day库等。
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
@media only screen and (max-width: 767px) {
  .redBlueCenterAll {
    width: 100%;
    .redBlueCenterFirst {
      height: 400px;
      background-image: url(../../../../assets/img/proctCenter/14.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      padding: 140px 40px;
      .redBlueCenterFirst1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        h2 {
          font-weight: bold;
          font-size: 16px;
          margin-bottom: 30px;
          color: #fff;
        }
        p {
          text-indent: 1.5rem;
          font-size: 14px;
          font-weight: 200;
          line-height: 30px;
        }
      }
    }
    .box {
      margin: 0 auto;
    }
    .tabs {
      display: flex;
      justify-content: center;
      height: 65px;
      padding: 0px 40px;
      border-bottom: 1px solid #dadada;
    }
    .tabs li {
      float: left;
      // margin-right: 87px;
      list-style: none;
    }
    .tabs .tab-link {
      display: block;
      width: 270px;
      height: 65px;
      text-align: center;
      line-height: 65px;
      font-size: 14px;
      color: #000;
      text-decoration: none;
    }
    .tabs .tab-link:hover {
      cursor: pointer;
      height: 65px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 5px solid #3d4ed0;
      transition: 0.3s;
    }

    .redBlueCenterSecond {
      background-color: #f6f6f6;
      padding: 20px 40px;
      h1 {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 30px;
        text-align: center;
      }

      .redBlueCenterSecond1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        .redBlueCenterSecond1Left {
          margin-right: 30px;
          img {
            width: 72vw;
            height: 38vh;
          }
        }
        .redBlueCenterSecond1Right {
          p {
            text-indent: 1.5rem;
            font-size: 14px;
            font-weight: 200;
            line-height: 30px;
          }
        }
      }
    }
    .redBlueCenterThird {
      background-color: #f6f6f6;
      padding: 30px;
      margin-top: 40px;
      h1 {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 30px;
        text-align: center;
      }
      p {
        text-indent: 1.5rem;
        font-size: 14px;
        font-weight: 200;
        line-height: 30px;
      }
      .redBlueCenterThird1 {
        display: flex;
        justify-content: center;
        align-items: center;
        .redBlueCenterThird1Left {
          width: 60%;
          margin-right: 30px;
        }
        .redBlueCenterThird1Right {
          img {
            width: 30vw;
            height: 26vh;
          }
        }
      }
    }
    .redBlueCenterFourth {
      display: flex;
      padding: 20px 40px;
      justify-content: center;
      img {
        width: 72vw;
        height: 38vh;
      }
    }
    .codeAuditThird {
      width: 100%;
      margin-top: 40px;
      background: #f1f5ff;
      padding: 20px 40px;
      h1 {
        text-align: center;
      }
      .codeAuditThird11 {
        width: 220px;
        text-align: center;
        margin-top: 20px;
        font-size: 20px;
      }
      ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
        li {
          display: flex;
          padding: 20px;
          background-image: linear-gradient(180deg, #f4f7ff, #fcfcfe);
          border-radius: 12px;
          margin-top: 20px;
          .codeAuditThird1-1 {
            img {
              width: 48px;
              height: 48px;
              margin-right: 20px;
            }
          }
          .codeAuditThird1-2 {
            .codeAuditThird1-2-1 {
              font-size: 24px;
              color: #1f2329;
            }
            .codeAuditThird1-2-2 {
              font-size: 16px;
              color: #51565d;
              margin-top: 20px;
              line-height: 26px;
            }
          }
        }
      }
    }
  }
}
</style>